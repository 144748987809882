
















































































































































































































































.el-message-box .el-message-box__title {
  font-size: .3rem;
  color:#448816;
  text-align: center;
  font-family: 'DFPFangYuanW7';  //重命名字体名
}
.page-wrapper-box {
  font-size: 0.16rem;
  width: 100%;
  height: 100%;
  display: flex;

  // padding: 3vh;
  .picLeft {
    width: 20%;
    height: 100%;
    margin-left: 2.5vw;
    border-radius: 30px;
    border: 0.03rem solid #ade787;
    background-color: rgba(255, 255, 255, 0.7);
    padding-bottom: 10px;
    overflow: hidden;
  }

  .picRight {
    padding: 0 2.5vw;
    width: 80%;
    height: 100%;
  }

  // .picBg {
  //   flex-grow: 1;
  //   width: 100%;
  //   background-image: url("../../assets/icon-select-right.png");
  // }
}
