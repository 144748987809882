.play-page-wrapper {
  width: 16.44rem;
  height: 7.61rem;
  margin: 0 auto;
  border-radius: 0.4rem;
  position: relative;
}
.play-page-wrapper .plye-page-box {
  width: 12rem;
  width: 100%;
  height: 100%;
  background: #ade787;
  border-radius: 0.4rem;
  position: absolute;
  margin-top: 0.9rem;
}
.play-page-wrapper .play-box {
  width: 100%;
  height: 100%;
  background: #ade787;
  border-radius: 0.4rem;
  position: relative;
}
.play-page-wrapper .play-box .play-left-box {
  display: inline-block;
  vertical-align: top;
  width: 13.12rem;
  height: 100%;
  background: #000000;
  background: #fff;
  overflow: hidden;
  position: relative;
  border-radius: 0.4rem;
  padding: 0.24rem;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
  background-color: rgba(4, 24, 4, 0.7);
  animation: self_fadeIn_1 0.2s linear forwards;
  position: relative;
  left: 0px;
  top: 0;
  z-index: 4;
  border-radius: 0.3rem;
  display: flex;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask img {
  border-radius: 0.3rem;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask .not-login-box {
  display: flex;
  width: 6rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  animation: self_fadeIn_1 0.3s linear forwards;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask .not-login-box .no_login_img {
  border-radius: 0px;
  width: 100%;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask .not-login-box p {
  color: #ffffff;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask .not-login-box .p1 {
  line-height: 0.25rem;
  height: 0.25rem;
  font-size: 0.18rem;
  font-weight: bold;
  margin-top: 0.4rem;
  margin-left: 0.3rem;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask .not-login-box .p2 {
  margin-top: 0.15rem;
  line-height: 0.1rem;
  height: 0.22rem;
  font-size: 0.16rem;
  width: 3.3rem;
  margin-left: 0.3rem;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask .not-login-box .p3 {
  margin: 0 auto;
  margin-top: 0.42rem;
  margin-top: 0.2rem;
  color: #fff;
  margin-left: 0.95rem;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask .not-login-box .p4 {
  margin-top: 0.24rem;
  line-height: 0.22rem;
  height: 0.22rem;
  font-size: 0.16rem;
  color: #fff;
  margin-left: 0.3rem;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask .not-login-box .p4 span {
  color: #fcae2c;
  font-weight: 700;
  cursor: pointer;
}
.play-page-wrapper .play-box .play-left-box .not-login-mask .not-login-box .p4 span:hover {
  text-decoration: underline;
}
.play-page-wrapper .play-box .play-left-box .login-btn {
  width: 2rem;
  height: 0.46rem;
  line-height: 0.46rem;
  text-align: center;
  font-size: 0.16rem;
  border-radius: 0.1rem;
}
.play-page-wrapper .play-box .play-left-box .not-login-img-cover {
  width: 12.6rem;
  height: 7.13rem;
  position: absolute;
  top: 0.25rem;
  border-radius: 0.36rem;
}
.play-page-wrapper .play-box .play-right-box {
  display: inline-block;
  vertical-align: top;
  width: 22.2%;
  width: 3.3rem;
  height: 100%;
  background: #ade787;
  padding-bottom: 0.1rem;
  overflow: hidden;
  border-radius: 0.3rem;
  position: relative;
  z-index: 10;
}
.play-page-wrapper .play-box .play-right-box .top-title {
  width: 100%;
  min-height: 0.76rem;
  line-height: 0.2rem;
  padding-left: 0.33rem;
  padding-right: 0.2rem;
  color: #696969;
  position: relative;
  overflow-x: auto;
}
.play-page-wrapper .play-box .play-right-box .top-title .packing-title {
  display: inline-block;
  color: #1c8816;
  font-size: 0.16rem;
}
.play-page-wrapper .play-box .play-right-box .top-title .play-count {
  color: #1c8816;
  font-size: 0.16rem;
}
.play-page-wrapper .play-box .play-right-box .top-title .play-count .color-green {
  font-size: 0.18rem;
  color: #ff9346;
}
.play-page-wrapper .play-box .play-right-box .top-title .img-collection {
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  top: 50%;
  margin-top: -0.13rem;
  right: 0.33rem;
  cursor: pointer;
}
.play-page-wrapper .play-box .play-right-box .top-title .img-collection:hover {
  filter: saturate(125%) brightness(108%);
}
@media (max-width: 1600px) {
  .play-page-wrapper .play-box .play-right-box .packing-title {
    font-size: 16px;
  }
  .play-page-wrapper .play-box .play-right-box .play-count {
    font-size: 16px;
  }
  .play-page-wrapper .play-box .play-right-box .play-count .color-green {
    font-size: 14px;
  }
  .play-page-wrapper .play-box .play-right-box .img-collection {
    right: 20px;
  }
}
.play-page-wrapper .play-box .play-right-box .list-box {
  height: calc(100% - 0.6rem);
  overflow: hidden;
  position: relative;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container-prev {
  position: absolute;
  top: -0.7rem;
  left: 4%;
  width: 92%;
  height: 0.6rem;
  background: #ade787;
  box-shadow: 0px 0.19rem 0.18rem -0.18rem #022b0e;
  z-index: 3;
  transition: top 0.3s;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container-prev:hover {
  top: 0 !important;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container-next {
  position: absolute;
  bottom: -0.7rem;
  left: 4%;
  width: 92%;
  height: 0.6rem;
  background: #ade787;
  box-shadow: 0 -0.19rem 0.18rem -0.18rem #022b0e;
  z-index: 3;
  transition: bottom 0.3s;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container-next:hover {
  bottom: 0 !important;
}
.play-page-wrapper .play-box .play-right-box .list-box .img-arrow-top,
.play-page-wrapper .play-box .play-right-box .list-box .img-arrow-bottom {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 0.4rem;
}
.play-page-wrapper .play-box .play-right-box .list-box .img-arrow-top.img-arrow-top,
.play-page-wrapper .play-box .play-right-box .list-box .img-arrow-bottom.img-arrow-top {
  bottom: 0.115rem;
}
.play-page-wrapper .play-box .play-right-box .list-box .img-arrow-top.img-arrow-bottom,
.play-page-wrapper .play-box .play-right-box .list-box .img-arrow-bottom.img-arrow-bottom {
  top: 0.105rem;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container {
  width: 100%;
  height: 100%;
  padding-top: 0.1rem;
  overflow: auto;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list {
  text-align: center;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 {
  display: inline-block;
  margin-bottom: 0.16rem;
  cursor: pointer;
  position: relative;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .resource-item-2-inner {
  width: 100%;
  height: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.45);
  border-radius: 0.244rem;
  border: 0.08rem solid #d9fdc2;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.166rem;
  position: relative;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .img-box .el-image__inner {
  transition: all 0.5s;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .img-box .el-image__inner:hover {
  transform: scale(1.1);
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .img-box .el-image {
  width: 100%;
  height: 100%;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .img-box .el-image .image-slot {
  width: 100%;
  height: 100%;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .img-box .el-image .image-slot .image-slot-placeholder {
  font-size: 0px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .img-box .el-image .image-slot .el-icon-picture-outline {
  font-size: 0px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/pic-loading-failed.png");
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .img-box .bottom-mask {
  width: 100%;
  height: 0.44rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  position: absolute;
  left: 0;
  bottom: 0;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .img-box .bottom-mask .resource-title {
  display: inline-block;
  width: calc(100% - 40px);
  height: 0.2rem;
  line-height: 0.2rem;
  text-align: left;
  font-size: 0.17rem;
  color: #ffffff;
  position: absolute;
  left: 0.14rem;
  bottom: 0.12rem;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .is-trailer {
  display: inline-block;
  width: 0.58rem;
  height: 0.5rem;
  line-height: 0.31rem;
  background-image: url("../../assets/new_ui/trailer.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  left: -0.13rem;
  top: 0.08rem;
  font-size: 0.14rem;
  color: #ffffff;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2 .resource-duration {
  width: 0.54rem;
  border-radius: 0.1446rem;
  height: 0.24rem;
  line-height: 0.24rem;
  font-size: 0.12rem;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 0.12rem;
  top: 0.12rem;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2.resource-item-selected .resource-item-2-inner {
  border-color: #fff;
  position: relative;
  z-index: 2;
}
.play-page-wrapper .play-box .play-right-box .list-box .arrow-container .content-list .resource-item-2.resource-item-selected .select-bg {
  width: calc(100% - 4px);
  height: 80%;
  background: #1a9928;
  border-radius: 0.24rem;
  z-index: 1;
  position: absolute;
  left: 0.02rem;
  bottom: -0.08rem;
}
.play-page-wrapper .play_shade {
  width: 17.68rem;
  position: absolute;
  bottom: -0.37rem;
  left: -0.61rem;
}
.play-page-wrapper .arrow-container::-webkit-scrollbar {
  display: block !important;
}
.arrow-container::-webkit-scrollbar-thumb {
  background-color: #86c35f !important;
}
.h16-10 .play-page-wrapper .plye-page-box {
  margin-top: 1.6rem;
}
