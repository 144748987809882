.resource-page-wrapper {
  width: 11.5rem;
  margin: auto;
  padding-top: 2.25rem;
  min-height: calc(100vh - 78px) !important;
  position: relative;
  z-index: 10;
}
.resource-page-wrapper .resource-content {
  padding: 50px;
  position: relative;
}
.resource-page-wrapper .resource-content .scroll-loading {
  width: 100%;
  height: 50px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: -50px;
}
.resource-page-wrapper .resource-content .scroll-loading .img-scroll-loading {
  width: 50px;
  height: 50px;
}
.resource-page-wrapper .no-data-box {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}
.resource-page-wrapper .resource-name {
  margin-top: 6px;
  font-size: 18px !important;
  font-size: 0.18rem !important;
  display: inherit;
}
