































































































































































.resource-page-wrapper {
  // width: 1100px;
  // width: 1210px;
  // width: 12.1rem;
  width: 11.5rem;
  margin: auto;
  // padding-top: 245px;
  // padding-top: 256px;
  padding-top: 2.25rem;
  // padding-top: 13.5%;
  // min-height: calc(100vh - 75px) !important;
  min-height: calc(100vh - 78px) !important;
  position: relative;
  z-index: 10;

  .resource-content {
    padding: 50px;

    position: relative;
    .scroll-loading {
      width: 100%;
      height: 50px;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: -50px;
      .img-scroll-loading {
        width: 50px;
        height: 50px;
      }
    }
  }
  .no-data-box {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
  .resource-name {
    margin-top: 6px;
    font-size: 18px !important;
    font-size: 0.18rem !important;

    display: inherit;
  }
}
