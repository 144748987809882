
























































































































































































































































































































































































































































































































#videoBox {
  // width: 12.23rem;
  // height: 6.88rem;
  height: 7.13rem;
  width: 100%;
  // height: 100%;
  position: relative;
  // margin-left: 20px;
  border-radius: 0.3rem;
  overflow: hidden;

  .img-cover {
    width: 100%;
    height: 100%;
  }
  #videoJs.video-js {
    // border-radius: 30px;
    // overflow: hidden;

    .vjs-control-bar {
      // overflow: hidden;
      width: 100%;
      height: 160px;
      // rem
      height: 1.6rem;
      border-radius: 1px;

      padding: 0 0.2rem;
      box-sizing: border-box;
      width: 100%;
      height: 1.6rem;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      border-radius: 0.3rem;
      // background: rgba(0, 0, 0, 0);

      > .vjs-control {
        width: 0.5rem;
        height: 0.5rem;
        // margin-top: 0.67rem;
        margin-top: 0.97rem;
        // color: #69c984;
        color: #95f280;

        // rem
        width: 0.5rem;
        height: 0.5rem;
        margin-top: 0.97rem;
      }
      .vjs-video-title {
        display: inline-block;
        width: calc(100% - 40px);
        position: absolute;
        left: 0.23rem;
        top: 0.33rem;
        color: #ffffff;
        font-size: 0.26rem;
        font-family: "DFPFangYuanW7";
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
      }
      .vjs-play-control {
        &.vjs-playing {
          background-image: url("../../assets/new_ui/vjs-pause.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          // background-position: center;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
        &.vjs-paused {
          background-image: url("../../assets/new_ui/vjs-play.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          // background-position: center;
          // margin-left: 10px;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
        &.vjs-ended {
          background-image: url("../../assets/new_ui/vjs-replay.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          background-position: center;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
        .vjs-icon-placeholder {
          display: none;
        }
      }
      .vjs-next-control {
        display: inline-block;
        width: 0.5rem;
        // height: 50px;
        // border-radius: 6px;
        background-image: url("../../assets/new_ui/vjs-next.png");
        background-repeat: no-repeat;
        background-size: 0.5rem 0.5rem;
        // background-position: center;
        // background-color: #ffffff;
        margin-left: 0.25rem;
        &:not(.vjs-next-control-disabled):hover {
          filter: saturate(125%) brightness(108%);
        }
        &.vjs-next-control-disabled {
          filter: grayscale(90%);
          cursor: not-allowed;
        }
      }
      .vjs-time-control {
        color: #ffffff;
        font-size: 0.18rem;
        font-family: "DFPFangYuanW7";
        display: inline-block;
        padding: 0 0.06rem;
        line-height: 0.4rem;
        height: 0.4rem;
        margin-top: 1.02rem;
        &.vjs-current-time {
          width: 0.8rem;
          text-align: right;
          // padding-left: 30px;
        }
        &.vjs-duration {
          width: 0.8rem;
          text-align: left;
          // padding-right: 30px;
        }
      }
      .vjs-time-divider {
        padding: 0;
        width: 0.06rem;
        min-width: 0.06rem;
        text-align: center;
        & > div > span {
          color: #ffffff;
        }
      }
      .vjs-progress-control {
        position: absolute;
        width: 100%;
        left: 0;
        // top: -0.4rem;
        top: -0.4rem;
        // margin-top: 60px;
        // margin-top: 0.6rem;
        .vjs-time-tooltip {
          font-family: "DFPFangYuanW7";
        }
        .vjs-progress-holder {
          height: 0.08rem !important;
          border-radius: 0.04rem;
          margin: 0 0.13rem;
          .vjs-time-tooltip {
            // top: -4.5em;
          }
          .vjs-play-progress {
            // background-color: #69c984;
            background-color: #95f280;
            border-radius: 0.04rem;
            &:before {
              transform: scale(0);
              font-size: 0.26rem;
              // color: pink;
              color: transparent;
              background: url("../../assets/new_ui/jindu_yuan.png") no-repeat;
              background-size: 100% 100%;
              top: 50%;
              margin-top: -0.13rem;
              transition: transform 0.2s;
            }
          }
          .vjs-load-progress {
            border-radius: 0.04rem;
          }

          .vjs-mouse-display {
            width: 0 !important;
          }
        }
        .vjs-progress-holder {
          height: 0.5rem;
        }
        &:hover {
          .vjs-progress-holder {
            height: 0.1rem !important;
            .vjs-play-progress {
              height: 0.1rem;
              &:before {
                transform: scale(1);
                font-size: 0.26rem;
                color: transparent;
                background: url("../../assets/new_ui/jindu_yuan.png") no-repeat;
                background-size: 100% 100%;
                border-radius: 50%;
                top: 50%;
                margin-top: -0.13rem;
              }
            }
          }
        }
      }
      .vjs-control.vjs-subs-caps-button,
      .vjs-control.vjs-audio-button,
      .vjs-control.vjs-playback-rate {
        .vjs-menu {
          width: 0.8rem;
          width: 0.64rem;
          .vjs-menu-content {
            // bottom: 24px;
            bottom: 0.35rem;
            border-radius: 0.0861rem;
            width: 100%;
            background: rgba(43, 51, 63, 0.7);
            max-height: 20em !important;
            .vjs-menu-item {
              width: 100%;
              // height: 30px;
              // line-height: 30px;
              height: 0.36rem;
              line-height: 0.36rem;
              padding: 0;
              text-align: center;
              color: #ffffff;
              font-size: 0.16rem;
              font-family: "DFPFangYuanW7";
              &.vjs-selected {
                // color: #69c984;
                color: #95f280;
                background: transparent;
              }
              &:hover {
                color: #ffffff;
                // background: rgba(105, 205, 163, 0.9);
                background: #7dd473;
              }
            }
          }
        }
      }
      .vjs-control.vjs-subs-caps-button {
        // height: 40px !important;

        // background-image: url("../../assets/new_ui/vjs-next.png");
        // background-repeat: no-repeat;
        // background-size: 50px 50px;
        background-position: center;
        padding: 0px 0.35rem;
        position: absolute;
        right: 3rem;
        top: 0.05rem;
        display: flex;
        height: 0.4rem !important;
        color: #fff;

        .vjs-menu {
          width: 1.5rem;
          // left: -56px;
          left: -0.4rem;
          // top: 40px;
          top: 0.34rem;
          .vjs-texttrack-settings {
            font-size: 0.14rem !important;
          }
        }
        // &:hover {
        // background: #63c155;
        // color: #7dd473 !important;

        // border-radius: 50px;
        // // background-image: url("../../assets/new_ui/btn_bg.png");
        // // background-size: 100% 100%;
        // // background-repeat: no-repeat;
        // background-position: center;
        // }
        .vjs-icon-placeholder {
          // display: none;
          &:before {
            content: "字幕";
            font-size: 0.18rem;
            // color: #fff;
            display: inline-block;
            // line-height: 48px;
            font-family: DFPFangYuanW7;

            // width: 53px;
            // height: 32px;
            // &:hover {
            //   background: linear-gradient(
            //     180deg,
            //     rgba(0, 0, 0, 0) 0%,
            //     rgba(0, 0, 0, 0.42) 100%
            //   );
            // }
          }
        }
      }
      .vjs-control.vjs-audio-button {
        // background-image: url("../../assets/new_ui/vjs-next.png");
        // background-repeat: no-repeat;
        // background-size: 50px 50px;
        // background-position: center;
        position: absolute;
        right: 2.3rem;
        top: 0.05rem;
        height: 0.4rem !important;

        padding: 0px 0.35rem;
        color: #fff;

        // .vjs-menu-button {
        //   color: #fff !important;
        // }
        // &:hover {
        //   color: #7dd473 !important;
        //   border-radius: 50px;
        // }

        .vjs-menu {
          left: 0.02rem;
          top: 0.34rem;
        }

        .vjs-icon-placeholder {
          &:before {
            content: "语言";
            font-size: 0.18rem;
            // color: #fff;
            display: inline-block;
            // line-height: 48px;
            font-family: DFPFangYuanW7;
          }
        }
      }

      .vjs-control.vjs-playback-rate {
        padding: 0px 0.35rem;
        position: absolute;
        right: 1.6rem;
        top: 0.05rem;
        // color: pink !important;
        // margin-left: calc(100% - 450px);
        margin-left: 0.3rem;
        font-family: "DFPFangYuanW7";
        font-size: 0.18rem;
        // height: 40px !important;

        // &:hover {
        //   color: #7dd473 !important;
        //   border-radius: 50px;
        // }
        .vjs-playback-rate-value {
          color: #fff;
          // top: 0.06rem;
          top: 0.02rem;
          font-size: 0.18rem;
          // color: #ffffff;
          // line-height: 38px;
          height: 100%;
          // &:hover {
          //   color: #7dd473 !important;
          // }
        }
        .vjs-menu {
          left: 0.02rem;
          top: 0.34rem;
        }
        .vjs-icon-placeholder {
        }
      }
      .vjs-volume-panel {
        position: absolute;
        right: 0.9rem;
        margin-left: 0.25rem;
        .vjs-mute-control {
          border-radius: 0.06rem;
          &.vjs-vol-0 {
            width: 0.46rem;

            background-image: url("../../assets/new_ui/vjs-mute.png");
            background-repeat: no-repeat;
            background-size: 0.5rem 0.5rem;
            // background-position: center;
            &:hover {
              filter: saturate(125%) brightness(108%);
            }
          }
          &.vjs-vol-1,
          &.vjs-vol-2,
          &.vjs-vol-3 {
            width: 0.46rem;
            background-image: url("../../assets/new_ui/vjs-vol.png");
            background-repeat: no-repeat;
            background-size: 0.5rem 0.5rem;
            // background-position: center;
            &:hover {
              filter: saturate(125%) brightness(108%);
            }
          }
          .vjs-icon-placeholder {
            display: none;
          }
        }
        .vjs-volume-control {
          width: 0.36rem;
          height: 1rem;
          border-radius: 0.06rem;
          bottom: 1.06rem !important;
          bottom: 0.98rem !important;
          .vjs-volume-bar {
            width: 0.04rem;
            height: 0.8rem;
            margin: 0.1rem auto;
            border-radius: 0.02rem;
            background: rgba(255, 255, 255, 0.5);
            .vjs-volume-level {
              width: 0.04rem;
              border-radius: 0.02rem;
              background: #69c984;
              &:before {
                font-size: 0.14rem;
                color: #ffffff;
              }
            }
          }
        }

        .vjs-volume-level {
          color: #69c984;
          &:before {
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .vjs-volume-vertical {
          bottom: 9em;
        }
        &.vjs-hover .vjs-volume-vertical {
          left: -0.38rem;
          // top: -95px;
        }
      }
      .vjs-fullscreen-control {
        position: absolute;
        right: 0.2rem;
        margin-left: 0.25rem;
        .vjs-icon-placeholder:before {
          content: "";
        }
        &[title="Fullscreen"],
        &[title="全屏"] {
          background-image: url("../../assets/new_ui/vjs-fullscreen.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          // background-position: center;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
        &[title="Non-Fullscreen"],
        &[title="退出全屏"] {
          background-image: url("../../assets/new_ui/vjs-fullscreen-exit.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          // background-position: center;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
          .vjs-icon-placeholder {
            display: none;
          }
        }
      }
      .vjs-button > .vjs-icon-placeholder:before {
        font-size: 2.8em;
      }
      .vjs-button > .vjs-icon-placeholder:before {
        line-height: 0.4rem;
      }
      .vjs-slider {
        background-color: rgba(255, 255, 255, 0.3);
      }
      .vjs-load-progress div {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 0.04rem;
      }
    }

    .vjs-big-play-button {
      width: 0.62rem;
      height: 0.62rem;
      margin-top: -0.31rem;
      margin-left: -0.31rem;
      background-image: url("../../assets/icon-play.png");
      background-repeat: no-repeat;
      background-size: 0.62rem 0.62rem;
      background-position: center;
      border-radius: 0;
      border: 0 solid transparent;
      background-color: transparent;
      .vjs-icon-placeholder {
        display: none;
      }
    }
    .vjs-modal-dialog-content {
      font-size: 0.18rem;
      color: #ffffff;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0);
      padding-top: 1rem;
    }
  }
  &.video-loading {
    &:before {
      content: "";
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -0.25rem;
      margin-top: -0.25rem;
      background-image: url("../../assets/video-loading.png");
      background-repeat: no-repeat;
      background-size: 0.5rem 0.5rem;
      animation: spin 1s infinite linear;
    }
  }
  .img-collection {
    width: 0.4rem;
    height: 0.4rem;
    position: absolute;
    top: 0.5rem;
    right: 0.4rem;
    cursor: pointer;
    &:hover {
      filter: saturate(125%) brightness(108%);
    }
  }
}
.video-js {
  border-radius: 0.3rem;
  background-color: transparent;
  width: 100% !important;
  height: 100% !important;
}
.vjs-poster {
  border-radius: 0.3rem;
  // background: rgba(0, 0, 0, 0);
  background-color: transparent;
}
.vjs-error-display {
  border-radius: 0.3rem;
}
.video-js .vjs-control-bar {
  // bottom: 0.01rem;
  // border-radius: 30px;
}

.video-js .vjs-tech {
  position: absolute;
  top: -0.02rem;
  left: -0.03rem;
  width: 101%;
  height: 101%;
  background: #000;
  border-radius: 0.01rem;
}

.video-js.vjs-fullscreen:not(.vjs-ios-native-fs) {
  .vjs-control-bar {
    border-radius: 0 !important;
  }
}
