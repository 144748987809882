








































































































.picListContainer {
  height: 100%;
  // min-height: max(768px, 100%);
  // background: red;

  .picLog {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    // border-radius: .3rem .3rem 0 0;
    background-color: #ade787;

    .picLog-title {
      font-size: 1vw;
      font-weight: bold;
      color: #448816;
    }
  }

  .picScrollContainer {
    height: 90%;
    width: 100%;
    margin-top: .1rem;
    // padding: 0 .05rem .3rem .1rem;

    .tipMessage {
      width: 100%;
      height: 100%;
      font-size: .2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #448816;

      .img-desc {
        width: 3vw;
        height: 3vw;
        background-image: url("../../assets/new_ui/icon-info1.png");
        background-repeat: no-repeat;
        background-size: 100%;
        margin-bottom: 1.5vw;
      }
    }

    #picListScrollContainer {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      text-align: center;
      padding-top: 10px;

      .img-pic-list {
        display: inline-block;
        width: 14.5vw;
        height: 14.5vw;
        line-height: 14.5vw;
        margin-bottom: 10px;
        vertical-align: middle;

        .img-pic {
          width: 100%;
          height: 100%;
          border-radius: 20px;
          border: 1px solid #ade787;
          overflow: hidden;

        }

        .active {
          .paintingFailText{
            box-shadow: 0 0 0 5px #448816;
          border-radius: 20px;
          }
          .paintingFailText0,.paintingFailText3{
            border: none;
          }
        }

        .paintingFailText {
          width: 14.5vw;
          height: 14.5vw;
          position: relative;
          color:#448816;
        }

        .paintingFailText3 {
          background: url('../../assets/ui_ai-fe/绘制失败.jpg') no-repeat;
          background-size: 50%;
          background-position: center 30%;
          overflow: hidden;
          border: 3px solid #ade787;
          border-radius: 20px;

          &::after {
            display: inline-block;
            width: 100%;
            text-align: center;
            content: '绘图失败';
            position: absolute;
            bottom: -3vw;
            left: 0;
          }
        }

        .paintingFailText1 {
          background: url('../../assets/ui_ai-fe/绘制失败.jpg') no-repeat;
          background-size: 50%;
          background-position: center 30%;

          &::after {
            display: inline-block;
            width: 100%;
            text-align: center;
            content: '加载图片失败';
            position: absolute;
            bottom: -3vw;
            left: 0;
          }
        }

        .paintingFailText0 {
          background: url('../../assets/ui_ai-fe/绘制中.jpg') no-repeat;
          background-size: 50%;
          background-position: center 30%;
          overflow: hidden;
          border: 3px solid #ade787;
          border-radius: 20px;

          &::after {
            display: inline-block;
            width: 100%;
            text-align: center;
            content: '加载中...';
            position: absolute;
            bottom: -3vw;
            left: 0;
          }
        }
      }
    }

    #picListScrollContainer::-webkit-scrollbar {
      display: block;
      width: 5px;
    }

    // 伪 ::scrollbar-thumb 元素设置

    #picListScrollContainer::-webkit-scrollbar-thumb {
      display: block;
      border-radius: 8px;
      background-color: #448816;
    }

    // 伪 ::scrollbar-track 元素设置

    // #picListScrollContainer::-webkit-scrollbar-track {
    //   display: block;
    //   border-radius: 8px;
    //   background-color: #cdcdcd;
    //   // border: 1px solid #000000;
    // }
  }
}
