
































































































































































































































































































































































































































































































































.picRightContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .picMainContainer {
    width: 100%;
    height: 82%;
    display: flex;
    justify-content: center;
    align-items: center;

    .picMain {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      border: 0.03rem solid #ade787;
      background-color: rgba(255, 255, 255, 0.7);

      .picMainItem {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 3.5vw;

        .picContainer {
          width: 30vw;
          height: 30vw;
          // background: #ccc;
          display: flex;
          flex-wrap: wrap;
          border-radius: 20px;
          border: 5px solid #ade787;
          overflow: hidden;

          .picMainPic {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1vw;

            .picStauts {
              width: 12vw;
              height: 12vw;
              font-weight: 500;
              line-height: 16vw;
              text-align: center;
              position: relative;
              transform: translateY(-1vw);
            }

            .picLoading {
              background-image: url(../../assets/ui_ai-fe/05.gif);
              background-size: 100%;
              background-repeat: no-repeat;

              &:after {
                width: 100%;
                color: "#448438";
                display: block;
                content: "正在绘画中...";
                font-size: 1.2vw;
                position: absolute;
                top: 5.5vw;
                color: #448816;
              }
            }

            .picError {
              background-image: url("../../assets/paintingFailed.jpg");
              background-size: 100%;
              background-repeat: no-repeat;

              &:after {
                width: 100%;
                color: "#448438";
                display: block;
                content: "绘图失败啦！请重试";
                font-size: 1.2vw;
                position: absolute;
                top: 5.5vw;
                color: #448816;
              }
            }

            .picFail {
              font-size: 30px;
              font-weight: 500;
              width: 100%;
              height: 20%;
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
            }
          }

          .img-pic {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;

            .el-image {
              width: 50%;
              height: 50%;
            }
          }
        }
      }

      .picMainText {
        width: 24vw;
        height: 30vw;
        padding: 1vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        border: 5px solid #ade787;

        .textInput {
          width: 100%;
          height: 12vw;
          text-align: left;

          // background: pink;
          .picTitle {
            margin-bottom: 1vw;
            font-size: 0.3rem;
            color: #448816;
          }

          .picDescribe {
            color: #448816;
            width: 95%;
            margin: 0 auto;
            min-height: 0.5rem;
            max-height: 1.8rem;
            border: 3px solid #ade787;
            border-radius: 1vw;
            padding: 0.4vw 0.3vw 0.4vw 0.5vw;

            .picDescribeMain {
              width: 100%;
              height: 100%;
              overflow-y: auto;
              padding-right: 0.4vw;
              color: #448816;
            }

            .picDescribeMain::-webkit-scrollbar {
              display: block;
              width: 10px;
            }

            // 伪 ::scrollbar-thumb 元素设置

            .picDescribeMain::-webkit-scrollbar-thumb {
              display: block;
              border-radius: 8px;
              background-color: #448816;
            }
          }
        }

        .textInfo {
          width: 100%;
          height: 30%;
          display: flex;
          justify-content: space-between;
          font-size: 1vw;

          .textInfoKey {
            height: 100%;
            width: 34%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: end;

            .itemKey {
              color: #448816;
              width: 100%;
              text-align: justify;
              text-align-last: justify;
              height: 25%;
            }
          }

          .textInfoValue {
            height: 100%;
            width: 65%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: start;

            .itemKey {
              color: #448816;
              text-align: left;
              height: 25%;
            }
          }
        }

        .textbutton {
          width: 100%;
          height: 15%;
          text-align: center;
          display: flex;
          align-items: center;

          .button-new {
            width: 6vw;
            height: 2.2vw;
            line-height: 1.8vw;
            border-radius: 1.1vw;
            border: 3px solid #448816;
            font-size: 1vw;
            font-weight: 600;
            margin-left: 1vw;
          }
        }
      }
    }

    .tree {
      width: 32vw;
      height: 32vw;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        width: 100%;
      }
    }

    .tree:after {
      content: "激发孩子无限创意，开启AI探索之旅!";
      position: absolute;
      bottom: -2vh;
      left: -5%;
      color: #448816;
      font-size: 1.8vw;
      display: block;
      width: 110%;
    }
  }

  .picSearch {
    width: 100%;
    max-height: 20%;
    min-height: 10%;
    display: flex;
    padding: 0.2vw;
    // margin: 10px;
    border: 1px solid #fff;
    border-radius: 20px;
    justify-content: space-around;
    background-color: #ffffff;
    align-items: center;

    .button-new {
      width: 4vw;
      height: 2.2vw;
      line-height: 1.8vw;
      border-radius: 1.1vw;
      border: 3px solid #448816;
      font-weight: 600;
      font-size: 1vw;
    }

    .btn {
      margin-left: 0.1vw;
    }

    .poneBtn {
      -webkit-touch-callout: none; // 禁止长按呼出菜单
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .poneImg {
      pointer-events: none;
      width: 3.5vw;
      height: 3.5vw;
    }

    .imgContener1 {
      -webkit-touch-callout: none;
      width: 3.5vw;
      height: 3.5vw;
      background-image: url("../../assets/语音.jpg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: #fff;
    }

    .imgContener2 {
      -webkit-touch-callout: none;
      width: 3.7vw;
      height: 3.7vw;
      background-image: url("../../assets/麦克风录音中.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: #fff;
    }

    .imgContener3 {
      -webkit-touch-callout: none;
      width: 3.5vw;
      height: 3.5vw;
      background-image: url("../../assets/语音灰.jpg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: #fff;
    }

    .textInput {
      width: 85%;
      min-height: 2vh;
      max-height: 13vh;
      // height: 100%;
      overflow-y: auto;
      line-height: 2vh;
      font-size: 1.5vh;
      // border: none;
      margin-left: 0.75vw;

      .el-textarea__inner {
        height: auto;
        padding: 5px 0;
      }
    }

    // .textInput::-webkit-scrollbar {
    //   display: block;
    //   width: 10px;
    // }

    // // 伪 ::scrollbar-thumb 元素设置

    // .textInput::-webkit-scrollbar-thumb {
    //   display: block;
    //   border-radius: 8px;
    //   background-color: #448816;
    // }
  }
}

.page-wrapper-box {
  button {
    border: none;
  }
}
