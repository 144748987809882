.picListContainer {
  height: 100%;
}
.picListContainer .picLog {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: #ade787;
}
.picListContainer .picLog .picLog-title {
  font-size: 1vw;
  font-weight: bold;
  color: #448816;
}
.picListContainer .picScrollContainer {
  height: 90%;
  width: 100%;
  margin-top: 0.1rem;
}
.picListContainer .picScrollContainer .tipMessage {
  width: 100%;
  height: 100%;
  font-size: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #448816;
}
.picListContainer .picScrollContainer .tipMessage .img-desc {
  width: 3vw;
  height: 3vw;
  background-image: url("../../assets/new_ui/icon-info1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 1.5vw;
}
.picListContainer .picScrollContainer #picListScrollContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  text-align: center;
  padding-top: 10px;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list {
  display: inline-block;
  width: 14.5vw;
  height: 14.5vw;
  line-height: 14.5vw;
  margin-bottom: 10px;
  vertical-align: middle;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .img-pic {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 1px solid #ade787;
  overflow: hidden;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .active .paintingFailText {
  box-shadow: 0 0 0 5px #448816;
  border-radius: 20px;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .active .paintingFailText0,
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .active .paintingFailText3 {
  border: none;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .paintingFailText {
  width: 14.5vw;
  height: 14.5vw;
  position: relative;
  color: #448816;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .paintingFailText3 {
  background: url('../../assets/ui_ai-fe/绘制失败.jpg') no-repeat;
  background-size: 50%;
  background-position: center 30%;
  overflow: hidden;
  border: 3px solid #ade787;
  border-radius: 20px;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .paintingFailText3::after {
  display: inline-block;
  width: 100%;
  text-align: center;
  content: '绘图失败';
  position: absolute;
  bottom: -3vw;
  left: 0;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .paintingFailText1 {
  background: url('../../assets/ui_ai-fe/绘制失败.jpg') no-repeat;
  background-size: 50%;
  background-position: center 30%;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .paintingFailText1::after {
  display: inline-block;
  width: 100%;
  text-align: center;
  content: '加载图片失败';
  position: absolute;
  bottom: -3vw;
  left: 0;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .paintingFailText0 {
  background: url('../../assets/ui_ai-fe/绘制中.jpg') no-repeat;
  background-size: 50%;
  background-position: center 30%;
  overflow: hidden;
  border: 3px solid #ade787;
  border-radius: 20px;
}
.picListContainer .picScrollContainer #picListScrollContainer .img-pic-list .paintingFailText0::after {
  display: inline-block;
  width: 100%;
  text-align: center;
  content: '加载中...';
  position: absolute;
  bottom: -3vw;
  left: 0;
}
.picListContainer .picScrollContainer #picListScrollContainer::-webkit-scrollbar {
  display: block;
  width: 5px;
}
.picListContainer .picScrollContainer #picListScrollContainer::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 8px;
  background-color: #448816;
}
