#videoBox {
  height: 7.13rem;
  width: 100%;
  position: relative;
  border-radius: 0.3rem;
  overflow: hidden;
}
#videoBox .img-cover {
  width: 100%;
  height: 100%;
}
#videoBox #videoJs.video-js .vjs-control-bar {
  height: 160px;
  border-radius: 1px;
  padding: 0 0.2rem;
  box-sizing: border-box;
  width: 100%;
  height: 1.6rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: 0.3rem;
}
#videoBox #videoJs.video-js .vjs-control-bar > .vjs-control {
  color: #95f280;
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 0.97rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-video-title {
  display: inline-block;
  width: calc(100% - 40px);
  position: absolute;
  left: 0.23rem;
  top: 0.33rem;
  color: #ffffff;
  font-size: 0.26rem;
  font-family: "DFPFangYuanW7";
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-play-control.vjs-playing {
  background-image: url("../../assets/new_ui/vjs-pause.png");
  background-repeat: no-repeat;
  background-size: 0.5rem 0.5rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-play-control.vjs-playing:hover {
  filter: saturate(125%) brightness(108%);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-play-control.vjs-paused {
  background-image: url("../../assets/new_ui/vjs-play.png");
  background-repeat: no-repeat;
  background-size: 0.5rem 0.5rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-play-control.vjs-paused:hover {
  filter: saturate(125%) brightness(108%);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-play-control.vjs-ended {
  background-image: url("../../assets/new_ui/vjs-replay.png");
  background-repeat: no-repeat;
  background-size: 0.5rem 0.5rem;
  background-position: center;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-play-control.vjs-ended:hover {
  filter: saturate(125%) brightness(108%);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-play-control .vjs-icon-placeholder {
  display: none;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-next-control {
  display: inline-block;
  width: 0.5rem;
  background-image: url("../../assets/new_ui/vjs-next.png");
  background-repeat: no-repeat;
  background-size: 0.5rem 0.5rem;
  margin-left: 0.25rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-next-control:not(.vjs-next-control-disabled):hover {
  filter: saturate(125%) brightness(108%);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-next-control.vjs-next-control-disabled {
  filter: grayscale(90%);
  cursor: not-allowed;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-time-control {
  color: #ffffff;
  font-size: 0.18rem;
  font-family: "DFPFangYuanW7";
  display: inline-block;
  padding: 0 0.06rem;
  line-height: 0.4rem;
  height: 0.4rem;
  margin-top: 1.02rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-time-control.vjs-current-time {
  width: 0.8rem;
  text-align: right;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-time-control.vjs-duration {
  width: 0.8rem;
  text-align: left;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-time-divider {
  padding: 0;
  width: 0.06rem;
  min-width: 0.06rem;
  text-align: center;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-time-divider > div > span {
  color: #ffffff;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control {
  position: absolute;
  width: 100%;
  left: 0;
  top: -0.4rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control .vjs-time-tooltip {
  font-family: "DFPFangYuanW7";
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control .vjs-progress-holder {
  height: 0.08rem !important;
  border-radius: 0.04rem;
  margin: 0 0.13rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control .vjs-progress-holder .vjs-play-progress {
  background-color: #95f280;
  border-radius: 0.04rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control .vjs-progress-holder .vjs-play-progress:before {
  transform: scale(0);
  font-size: 0.26rem;
  color: transparent;
  background: url("../../assets/new_ui/jindu_yuan.png") no-repeat;
  background-size: 100% 100%;
  top: 50%;
  margin-top: -0.13rem;
  transition: transform 0.2s;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control .vjs-progress-holder .vjs-load-progress {
  border-radius: 0.04rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control .vjs-progress-holder .vjs-mouse-display {
  width: 0 !important;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control .vjs-progress-holder {
  height: 0.5rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control:hover .vjs-progress-holder {
  height: 0.1rem !important;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control:hover .vjs-progress-holder .vjs-play-progress {
  height: 0.1rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-progress-control:hover .vjs-progress-holder .vjs-play-progress:before {
  transform: scale(1);
  font-size: 0.26rem;
  color: transparent;
  background: url("../../assets/new_ui/jindu_yuan.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 50%;
  top: 50%;
  margin-top: -0.13rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-subs-caps-button .vjs-menu,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-audio-button .vjs-menu,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-playback-rate .vjs-menu {
  width: 0.8rem;
  width: 0.64rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-subs-caps-button .vjs-menu .vjs-menu-content,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-audio-button .vjs-menu .vjs-menu-content,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-playback-rate .vjs-menu .vjs-menu-content {
  bottom: 0.35rem;
  border-radius: 0.0861rem;
  width: 100%;
  background: rgba(43, 51, 63, 0.7);
  max-height: 20em !important;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-subs-caps-button .vjs-menu .vjs-menu-content .vjs-menu-item,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-audio-button .vjs-menu .vjs-menu-content .vjs-menu-item,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-playback-rate .vjs-menu .vjs-menu-content .vjs-menu-item {
  width: 100%;
  height: 0.36rem;
  line-height: 0.36rem;
  padding: 0;
  text-align: center;
  color: #ffffff;
  font-size: 0.16rem;
  font-family: "DFPFangYuanW7";
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-subs-caps-button .vjs-menu .vjs-menu-content .vjs-menu-item.vjs-selected,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-audio-button .vjs-menu .vjs-menu-content .vjs-menu-item.vjs-selected,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-playback-rate .vjs-menu .vjs-menu-content .vjs-menu-item.vjs-selected {
  color: #95f280;
  background: transparent;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-subs-caps-button .vjs-menu .vjs-menu-content .vjs-menu-item:hover,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-audio-button .vjs-menu .vjs-menu-content .vjs-menu-item:hover,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-playback-rate .vjs-menu .vjs-menu-content .vjs-menu-item:hover {
  color: #ffffff;
  background: #7dd473;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-subs-caps-button {
  background-position: center;
  padding: 0px 0.35rem;
  position: absolute;
  right: 3rem;
  top: 0.05rem;
  display: flex;
  height: 0.4rem !important;
  color: #fff;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-subs-caps-button .vjs-menu {
  width: 1.5rem;
  left: -0.4rem;
  top: 0.34rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-subs-caps-button .vjs-menu .vjs-texttrack-settings {
  font-size: 0.14rem !important;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-subs-caps-button .vjs-icon-placeholder:before {
  content: "字幕";
  font-size: 0.18rem;
  display: inline-block;
  font-family: DFPFangYuanW7;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-audio-button {
  position: absolute;
  right: 2.3rem;
  top: 0.05rem;
  height: 0.4rem !important;
  padding: 0px 0.35rem;
  color: #fff;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-audio-button .vjs-menu {
  left: 0.02rem;
  top: 0.34rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-audio-button .vjs-icon-placeholder:before {
  content: "语言";
  font-size: 0.18rem;
  display: inline-block;
  font-family: DFPFangYuanW7;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-playback-rate {
  padding: 0px 0.35rem;
  position: absolute;
  right: 1.6rem;
  top: 0.05rem;
  margin-left: 0.3rem;
  font-family: "DFPFangYuanW7";
  font-size: 0.18rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-playback-rate .vjs-playback-rate-value {
  color: #fff;
  top: 0.02rem;
  font-size: 0.18rem;
  height: 100%;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-control.vjs-playback-rate .vjs-menu {
  left: 0.02rem;
  top: 0.34rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel {
  position: absolute;
  right: 0.9rem;
  margin-left: 0.25rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control {
  border-radius: 0.06rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control.vjs-vol-0 {
  width: 0.46rem;
  background-image: url("../../assets/new_ui/vjs-mute.png");
  background-repeat: no-repeat;
  background-size: 0.5rem 0.5rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control.vjs-vol-0:hover {
  filter: saturate(125%) brightness(108%);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control.vjs-vol-1,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control.vjs-vol-2,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control.vjs-vol-3 {
  width: 0.46rem;
  background-image: url("../../assets/new_ui/vjs-vol.png");
  background-repeat: no-repeat;
  background-size: 0.5rem 0.5rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control.vjs-vol-1:hover,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control.vjs-vol-2:hover,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control.vjs-vol-3:hover {
  filter: saturate(125%) brightness(108%);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-mute-control .vjs-icon-placeholder {
  display: none;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-control {
  width: 0.36rem;
  height: 1rem;
  border-radius: 0.06rem;
  bottom: 1.06rem !important;
  bottom: 0.98rem !important;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-control .vjs-volume-bar {
  width: 0.04rem;
  height: 0.8rem;
  margin: 0.1rem auto;
  border-radius: 0.02rem;
  background: rgba(255, 255, 255, 0.5);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-control .vjs-volume-bar .vjs-volume-level {
  width: 0.04rem;
  border-radius: 0.02rem;
  background: #69c984;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-control .vjs-volume-bar .vjs-volume-level:before {
  font-size: 0.14rem;
  color: #ffffff;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-level {
  color: #69c984;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-level:before {
  left: 50%;
  transform: translateX(-50%);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-vertical {
  bottom: 9em;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-volume-panel.vjs-hover .vjs-volume-vertical {
  left: -0.38rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control {
  position: absolute;
  right: 0.2rem;
  margin-left: 0.25rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "";
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="Fullscreen"],
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="全屏"] {
  background-image: url("../../assets/new_ui/vjs-fullscreen.png");
  background-repeat: no-repeat;
  background-size: 0.5rem 0.5rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="Fullscreen"]:hover,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="全屏"]:hover {
  filter: saturate(125%) brightness(108%);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="Non-Fullscreen"],
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="退出全屏"] {
  background-image: url("../../assets/new_ui/vjs-fullscreen-exit.png");
  background-repeat: no-repeat;
  background-size: 0.5rem 0.5rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="Non-Fullscreen"]:hover,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="退出全屏"]:hover {
  filter: saturate(125%) brightness(108%);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="Non-Fullscreen"] .vjs-icon-placeholder,
#videoBox #videoJs.video-js .vjs-control-bar .vjs-fullscreen-control[title="退出全屏"] .vjs-icon-placeholder {
  display: none;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-button > .vjs-icon-placeholder:before {
  font-size: 2.8em;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-button > .vjs-icon-placeholder:before {
  line-height: 0.4rem;
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-slider {
  background-color: rgba(255, 255, 255, 0.3);
}
#videoBox #videoJs.video-js .vjs-control-bar .vjs-load-progress div {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0.04rem;
}
#videoBox #videoJs.video-js .vjs-big-play-button {
  width: 0.62rem;
  height: 0.62rem;
  margin-top: -0.31rem;
  margin-left: -0.31rem;
  background-image: url("../../assets/icon-play.png");
  background-repeat: no-repeat;
  background-size: 0.62rem 0.62rem;
  background-position: center;
  border-radius: 0;
  border: 0 solid transparent;
  background-color: transparent;
}
#videoBox #videoJs.video-js .vjs-big-play-button .vjs-icon-placeholder {
  display: none;
}
#videoBox #videoJs.video-js .vjs-modal-dialog-content {
  font-size: 0.18rem;
  color: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0);
  padding-top: 1rem;
}
#videoBox.video-loading:before {
  content: "";
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.25rem;
  margin-top: -0.25rem;
  background-image: url("../../assets/video-loading.png");
  background-repeat: no-repeat;
  background-size: 0.5rem 0.5rem;
  animation: spin 1s infinite linear;
}
#videoBox .img-collection {
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
  top: 0.5rem;
  right: 0.4rem;
  cursor: pointer;
}
#videoBox .img-collection:hover {
  filter: saturate(125%) brightness(108%);
}
.video-js {
  border-radius: 0.3rem;
  background-color: transparent;
  width: 100% !important;
  height: 100% !important;
}
.vjs-poster {
  border-radius: 0.3rem;
  background-color: transparent;
}
.vjs-error-display {
  border-radius: 0.3rem;
}
.video-js .vjs-tech {
  position: absolute;
  top: -0.02rem;
  left: -0.03rem;
  width: 101%;
  height: 101%;
  background: #000;
  border-radius: 0.01rem;
}
.video-js.vjs-fullscreen:not(.vjs-ios-native-fs) .vjs-control-bar {
  border-radius: 0 !important;
}
